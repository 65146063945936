
const About = () => {
    return (
        <div className='box content page'>
            <p>This is allll about us. And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....   And then....   
                And then....   And then....
            </p>
        </div>
    )
}

export default About